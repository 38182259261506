<template>
  <div class="solution_manager__search_field" data-cy="solution_manager_search">
    <component :is="`h${headingLevel}`" :id="titleId" class="subtitle"
      >Sie fragen, wir antworten!</component
    >

    <div class="search_field__wrapper row grow" :aria-labelledby="titleId">
      <Textfield
        ref="searchfield"
        class="field"
        data-cy="search_textfield"
        label="Geben Sie hier Ihre Frage ein..."
        :model-value="searchTerm"
        disable-checkmark
        :on-enter="onEnter"
        :rules="[rules.minlength]"
        :autofocus="autofocus"
        @update:model-value="onInput"
      />

      <ButtonComponent
        class="help_preview__search_button"
        data-cy="search_textfield_button"
        :clickhandler="onEnter"
      >
        <span class="visually-hidden">Suchen</span>
        <Icon
          class="help_preview__search_icon"
          name="magnifying-glass"
          height="26"
        />
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from '../icon/icon.vue'
import Textfield from 'src/components/common/input/textfield.vue'

export default {
  name: 'SolutionManagerSeachField',
  components: {
    Textfield,
    ButtonComponent,
    Icon
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    submit: {
      type: Function,
      default: undefined
    },
    preview: {
      type: Boolean,
      default: false
    },
    headingLevel: {
      type: Number,
      default: 2
    },
    titleId: {
      type: String,
      default: undefined
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searchTerm: this.modelValue,
      rules: {
        minlength: val => {
          return (
            (val && val.length > 2) ||
            'Bitte geben Sie mindestens 3 Zeichen ein.'
          )
        }
      }
    }
  },
  computed: {
    onEnter() {
      return this.preview || !this.submit
        ? this.openSolutionManager
        : this.submit
    }
  },
  watch: {
    modelValue(text) {
      this.searchTerm = text
    }
  },
  methods: {
    onInput(event) {
      this.searchTerm = event
      this.$emit('update:modelValue', this.searchTerm)
    },
    validate(showError) {
      const isValid = this.$refs.searchfield.validate(showError)

      if (!isValid) {
        this.$refs.searchfield.focus()
      }

      return isValid
    },
    openSolutionManager() {
      if (this.validate(true)) {
        this.$bus.emit(
          'open-solution-manager',
          this.searchTerm,
          this.$refs.searchfield
        )
      }
    },
    focus() {
      this.$refs.searchfield.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.solution_manager__search_field {
  width: 100%;

  .search_field__wrapper {
    min-height: 85px;

    .help_preview__search_button {
      max-height: calc(24px + 2rem);
      width: 60px;
      min-width: auto;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:focus {
        z-index: 1;
        border-radius: 3px;
      }

      @include breakpoint($from-tablet) {
        width: 85px;
      }

      :deep(.button-label) {
        min-width: auto;
      }
    }

    .field {
      cursor: text;
      min-width: 150px;
      flex-grow: 1;

      :deep(.input__slot:not(.textfield__focused)),
      :deep(.input__slot.textfield__clicked) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .help_preview__search_icon {
      cursor: pointer;
    }
  }

  .subtitle {
    margin-bottom: 10px;
    margin-top: 0;
  }
}
</style>
